import React from 'react'
import "../Stylesheets/Homepage.css";
import Lottie from "lottie-react";
import {motion} from "framer-motion";
import Scrolldown from "../Resources/scroll-down.json";
import PortFolioImage from "../Resources/Homepage/PortfolioImg.jpg"

const LandingIntro = () => {
  return (
    <div className='landing-intro'>
            <div className='flex-custom center-flex landing-flex'>
                <div className='sub-home flex-custom'>
                    <div className='intro-text intro-animation flex-custom center-flex flex-column flex-mobile'>
                        <motion.h1 initial={{opacity:0, x:-80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} className='profile-name montserrat'>KS Bhatia</motion.h1>
                        <motion.p initial={{opacity:0, x:-80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} className='quicksand'>Serial Entrepreneur | Founder at MrProptek.com | Building Disruptive AI Proptech Platform | Global Disruptor</motion.p>
                    </div>
                    <div className='profile-image intro-animation flex-custom center-flex'>
                        <motion.img initial={{opacity:0, x:80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} src={PortFolioImage} alt='Profile-image'></motion.img>
                    </div>
                </div>
            </div>
            <a href='#about-section'>
                <Lottie className='scroll-animation' animationData={Scrolldown} loop={true} />
            </a>
    </div>
  )
}

export default LandingIntro