import React from "react";
import DrawerAppBar from "./Navbar";
import Subheading from "./SubHeading";
import { motion } from "framer-motion";
import ParaContent from "./paraContent";
import "../Stylesheets/About.css";
import Footer from "./Footer";
import About1 from "../Resources/About/About1.png";
import About2 from "../Resources/About/About2.png";

const variants = {
    visible: {opacity: 1, x: 0},
    hidden: {opacity: 0, x: 80}
}

const proptekcontent = ['Mr. Proptek, an innovative B2C platform founded by K.S. Bhatia in October 2024, is transforming the real estate industry with its AI-driven solutions. By leveraging cutting-edge technologies like artificial intelligence, AR, VR & 3D Rendering, the platform empowers smarter decision-making, streamlines transactions, and predicts market trends, making property buying and selling more accessible, transparent, and efficient. Aimed at disrupting traditional practices and becoming a global brand, Mr. Proptek provides data-driven insights and intelligent tools that ensure faster, more profitable property transactions. ', <br />, "With Dubai's thriving real estate market and its people's strong embrace of technology, Mr. Proptek is set to launch in March 2025, bringing its revolutionary platform to one of the world's most dynamic property landscapes."];
const pumpkartContent = ["Pumpkart, founded by K.S. Bhatia in 2014, revolutionized the water pump industry by introducing a digital-first approach to a traditionally fragmented market. The platform redefined how consumers accessed water pumps and related products, offering a seamless online experience that simplified the buying process. Recognized for its transformative impact, Pumpkart was even lauded by Google CEO Sundar Pichai in front of the Indian Prime Minister, underscoring its significance. The company raised over $2 million in funding and achieved a valuation exceeding ₹100 crore.", <br />, "By prioritizing convenience, transparency, and quality, Pumpkart set a new benchmark for the water pump industry, exemplifying how technology and thoughtful service design can transform traditional business sectors."];


const AboutUs = () => {
  return (
    <div>
      <DrawerAppBar />
      <div style={{ padding: "20px" }}></div>
      <Subheading content="Dr. KS Bhatia" />
      <div className="main-about">
        <div className="about-main">
          <div className="about-left about-page-img profile-image flex-custom center-flex">
            <motion.img
              initial={{ opacity: 0, x: -80 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              src={About1}
              alt="about-img"
            ></motion.img>
          </div>
          <div className="about-right body-color-blue flex-custom center-flex">
            <div style={{ width: "80%" }} className="about-right-top">
              <h2 className="montserrat about-page-sub-head">Mr. Proptek</h2>
              <ParaContent
                className="quicksand about-text"
                content={proptekcontent}
              />
            </div>
          </div>
        </div>
      
        <div className="about-main2">
          
        <div className="about-right body-color-blue flex-custom center-flex">
            <div style={{ width: "80%" }} className="about-right-top">
              <h2 className="montserrat about-page-sub-head">PumpKart</h2>
              <ParaContent
                className="quicksand about-text"
                content={pumpkartContent}
              />
            </div>
          </div>
          
          <div className="about-left profile-image flex-custom center-flex">
            <motion.img
              variants={variants} 
              initial="hidden"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              whileInView="visible"
              viewport={{once: true, amount: 0.5}}
              src={About2}
              alt="about-img"
            ></motion.img>
          </div> 
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default AboutUs;
