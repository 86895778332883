import React from 'react'
import "../Stylesheets/Homepage.css";
import {motion} from "framer-motion";
import Subheading from './SubHeading';
import ParaContent from './paraContent';
import MotionButton from './MotionButton';
import AboutImg from '../Resources/Homepage/HomeAbout.jpg';

const content = ["As a serial entrepreneur from India and the founder of Mr. Proptek, an AI-driven platform revolutionizing the real estate buying process, I am committed to shaping the future of property transactions. With over 25 years of business experience, my focus is on driving disruptive innovation in the proptech space. Mr. Proptek, scheduled to launch in Dubai in March 2025, is set to become a global brand, utilizing advanced artificial intelligence, AR, VR, and 3D technology to streamline property buying and selling, improve decision-making, and forecast market trends.", <br />, "Previously, I founded Pumpkart, which transformed the water pumps industry and earned recognition from Google CEO Sundar Pichai in the presence of India’s Prime Minister. A global thought leader, I was the first Indian startup founder to speak at the United Nations and have received accolades such as the Leadership Award from RMAI and Amity University, as well as recognition by TIMES as a Top 30 leader. I hold a B.Tech in Chemical Engineering from Bangalore University, an MBA from IMT Ghaziabad, and an honorary doctorate from Sorbonne University, France."]

const HomeAbout = () => {

  return (
    <div id='about-section'>
        <div className='about-main about-mobile'>
            <div className='about-left profile-image flex-custom center-flex'>
                <motion.img initial={{opacity:0, x:-80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} src={AboutImg} alt='about-img'></motion.img>
            </div>
            <div className='about-right body-color-blue flex-custom center-flex'>
                <div className='about-right-top'>
                    <Subheading content="About Me" />
                    <ParaContent className="quicksand about-text" content={content} />
                    <MotionButton location="/about" content='Know More' />
                </div>            
            </div>
        </div>
    </div>
  )
}

export default HomeAbout