import React from 'react'
import Subheading from './SubHeading'
import "../Stylesheets/Homepage.css";
import WorkCard from './WorkCard';
import WorkImg1 from '../Resources/Homepage/WorkSection/Mrproptek.png';
import WorkImg2 from '../Resources/Homepage/WorkSection/pumpkart_logo.jpeg';
import WorkImg3 from '../Resources/Homepage/WorkSection/figgital.jpeg';
import WorkImg4 from '../Resources/Homepage/WorkSection/air.png';

const WorkSection = () => {
  return (
    <div className='work-section'>
        <Subheading content='Works' />
        <div className='work-main'>
            <WorkCard 
                image= {WorkImg1}
                title='Mr. Proptek'
                content='Mr. Proptek, an AI-driven B2C platform founded by K.S. Bhatia in October 2024, is revolutionizing the real estate industry by simplifying property transactions. Aiming to become a global brand, the platform uses artificial intelligence, AR, VR & 3D Technology to improve decision-making, streamline processes, and predict market trends. We will be launching this platform in Dubai in March 2025, capitalizing on the region’s quick adoption of technology. Focused on disrupting conventional real estate practices, Mr. Proptek delivers smarter, more efficient, and accessible solutions to buyers and sellers around the globe.'
            />
            
            <WorkCard 
                image={WorkImg2}
                title='PumpKart'
                content='Pumpkart, founded by K.S Bhatia in May 2014, is an Indian e-commerce platform that revolutionized the water pump market by providing a wide range of pumps and hardware products online. Recognized for its impact, even highlighted by Google’s Sundar Pichai in front of Prime Minister of India, The company raised over $2 million in funding and achieved a valuation exceeding ₹100 crore.  Pumpkart streamlined a fragmented industry and empowered small manufacturers with a digital marketplace to reach broader audiences.'
            />
            
            <WorkCard 
                image={WorkImg3}
                title='Figgital'
                content="Figgital, founded in October 2020 by K.S Bhatia with an initial investment of ₹25,000, merged physical and digital retail experiences through an omnichannel model. The brand gained significant traction and also received a grant of ₹3.5 crore in 2021. In 2024, Figgital was successfully sold to one of the India's largest modern retail company."
            />

            <WorkCard 
                image={WorkImg4}
                title='Air Fluid Engineers & Equipments'
                content='Airfluid Engineers & Equipments, founded by K.S Bhatia in May 1998 with an initial investment of ₹45,000, specialized in B2B where company taking contracts for installing Pumping Systems in Northern India. By 2012, the company grew to ₹25 crore in revenue and by 2017, the company touched a revenue of ₹50 crores. Operational until March 2019, AirFluid was one of the leading water pumps contractor in Northern India accross industry, commercial Buildings, institutions, OEMs. '
                last="true"
            />
        </div>
    </div>
  )
}

export default WorkSection